/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {

    var isHome = $('body.home').length > 0;
    var isBlog = $('body.blog').length > 0 || $('body.single').length > 0|| $('body.archive').length > 0;
    var isLove = $('body.love').length > 0;
    var isConnect = $('body.connect').length > 0;
    var isLegal = $('body.legal').length > 0;
    var isRetreatsApply = $('body.retreats-apply').length > 0;
    var isContactTemplate = $('body.page-template-template-contact').length > 0;
    var isCaptainsHouseApply = $('body.captains-house-apply').length > 0
    var isThoughtPartnershipApply = $('body.thought-partnership-apply').length > 0;
    var isTeamsApply = $('body.teams-apply').length > 0;
    var isExecutiveCoachingApply = $('body.executive-coaching-apply').length > 0;
    var isSearch = $('body.search').length > 0;
    var is404 = $('body.error404').length > 0;

    var directlyDisplayStickyTopNav = isBlog || isSearch || isLove || isConnect
                                        || isLegal || isRetreatsApply || isContactTemplate || isCaptainsHouseApply
                                        || isThoughtPartnershipApply
                                        || is404 || isTeamsApply || isExecutiveCoachingApply;

    var mdHeaderEle = '#header-sticky-md';
    var smHeaderEle = '#header-sticky-sm';
    var mdMenuEle = '#header-sticky-md .menu';
    var smMenuEle = '#header-sticky-sm .menu';

    var headerclassName = 'navbar-fixed-top animated slideInDown';
    var wrapEle = '.wrap';

    function checkForStickyNav(topThresold) {

        if ($(window).width() > 991) {
            if ($(window).scrollTop() > topThresold) {
                $(mdHeaderEle).addClass(headerclassName);
                // $(mdMenuEle).addClass('col-md-7');
                // $(mdMenuEle).removeClass('col-md-8 col-md-offset-1');
            }
            else {
                $(mdHeaderEle).removeClass(headerclassName);
                // $(mdMenuEle).addClass('col-md-8 col-md-offset-1');
                // $(mdMenuEle).removeClass('col-md-7');
            }
        }
        else {
            if ($(window).scrollTop() > topThresold) {
                $(smHeaderEle).addClass(headerclassName);
                // $(smMenuEle).addClass('col-sm-7');
                // $(smMenuEle).removeClass('col-sm-9');
            }
            else {
                $(smHeaderEle).removeClass(headerclassName);
                // $(smMenuEle).addClass('col-sm-9');
                // $(smMenuEle).removeClass('col-sm-7');
            }
        }
    }

    /**
     * content slide on scroll
     * https://css-tricks.com/slide-in-as-you-scroll-down-boxes/
     */
    (function($) {
        $.fn.visible = function(partial) {
            var $t            = $(this),
                $w            = $(window),
                viewTop       = $w.scrollTop(),
                viewBottom    = viewTop + $w.height(),
                _top          = $t.offset().top;
            return (viewBottom >= _top);
        };
    })($);

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                new WOW().init();

                var topThresold = 0;
                $(window).scroll(function () {
                    topThresold = isHome ? $(wrapEle).position().top : 125;
                    topThresold = directlyDisplayStickyTopNav ? -1 : topThresold;
                    checkForStickyNav(topThresold);
                });

                $(window).resize(function () {
                    topThresold = isHome ? $(wrapEle).position().top : 125;
                    topThresold = directlyDisplayStickyTopNav ? -1 : topThresold;
                    checkForStickyNav(topThresold);
                });

                // do the initial
                topThresold = isHome ? $(wrapEle).position().top : 125;
                topThresold = directlyDisplayStickyTopNav ? -1 : topThresold;
                checkForStickyNav(topThresold);

                $('.nav-sticky ul.sub-menu').each(function(){
                    $(this).addClass("dropdown-menu");
                });

                $('.nav-sticky .menu-item-has-children a').dropdownHover({
                    delay: 300,
                    instantlyCloseOthers: false
                });


                $('.hoverable').mouseenter(function () {
                    $(this).find('.read-more').fadeTo('slow', 0.85);
                }).mouseleave(function () {
                    $(this).find('.read-more').fadeTo('slow', 0);
                });

                $(".search .btn-search").click(function () {
                    $('.search-form').toggleClass("hide");
                    $('.btn-search .fa-search').toggleClass("hide");
                    $('.btn-search .fa-times').toggleClass("hide");
                });

                $('form.mc-form').ajaxChimp();

                $(".carousel").swiperight(function() {
                    $(this).carousel('prev');
                });
                $(".carousel").swipeleft(function() {
                    $(this).carousel('next');
                });

            },
            finalize: function () {


                var $primeItems = [
                    '.content p',
                    '.content input',
                    '.content textarea',
                    '.content img',
                    '.content h1',
                    '.content h2',
                    '.content h3',
                    '.content ul li',
                    '.content .btn',
                    '.content .button'
                ];

                for (var i = 0, l = $primeItems.length; i < l; i++) {
                    var $comeInItems = $($primeItems[ i ]);

                    $comeInItems.each(function(i, el){
                        var $el = $(el);
                        if( !$el.hasClass('no-fade') && !$el.parents().hasClass('no-fade') ) {
                            $el.addClass('content-primed');
                        }
                    });
                }

                var win = $(window);
                var $allMods = $('.content-primed');

                $(document).ready(function() {
                    // Already visible modules
                    $allMods.each(function(i, el) {
                        $allMods = $('.content-primed');
                        var $el = $(el);
                        if ($el.visible(true)) {
                            $el.addClass('fade-in').removeClass('content-primed');
                        }
                    });

                    win.scroll(function(event) {
                        $allMods = $('.content-primed');
                        $allMods.each(function(i, el) {
                            var $el = $(el);
                            if ($el.visible(true)) {
                                $el.addClass('fade-in').removeClass('content-primed');
                            }
                        });
                    });
                });


            }
        },
        // Home page
        'home': {
            init: function () {

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'services': {
            init: function () {
                $('.hidden-xs .service').mouseenter(function () {
                    $(this).find('.meta').fadeTo('slow', 0);
                    $(this).find('.row').fadeTo('slow', 1);
                }).mouseleave(function () {
                    $(this).find('.meta').fadeTo('slow', 1);
                    $(this).find('.row').fadeTo('slow', 0);
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'blog': {
            init: function () {

                $(window).load(function () {
                    $('article .img .read-more').each(function () {
                        var imgHeight = $(this).next().height();
                        $(this).height(imgHeight);
                    });
                });


                $('.grid').imagesLoaded(function () {
                    function comparatorOrder(a, b) {
                        return $(a).data('order') < $(b).data('order') ? -1 : 1;
                    }

                    var wookmark = new Wookmark('.grids', {
                        autoResize: true,
                        offset: 20, // Optional, the distance between grid items
                        outerOffset: 0, // Optional, the distance to the containers border
                        itemWidth: 300, // Optional, the width of a grid item
                        comparator: comparatorOrder
                    });
                });
            }
        },
        'archive': {
            init: function () {

                $(window).load(function () {
                    $('article .img .read-more').each(function () {
                        var imgHeight = $(this).next().height();
                        $(this).height(imgHeight);
                    });
                });


                $('.grid').imagesLoaded(function () {
                    function comparatorOrder(a, b) {
                        return $(a).data('order') < $(b).data('order') ? -1 : 1;
                    }

                    var wookmark = new Wookmark('.grids', {
                        autoResize: true,
                        offset: 20, // Optional, the distance between grid items
                        outerOffset: 0, // Optional, the distance to the containers border
                        itemWidth: 300, // Optional, the width of a grid item
                        comparator: comparatorOrder
                    });
                });
            }
        },
        'single': {
            init: function () {
                $('#collapsibleComments').on('shown.bs.collapse', function () {
                    $('.count .fa').toggleClass('hide');
                });
                $('#collapsibleComments').on('hidden.bs.collapse', function () {
                    $('.count .fa').toggleClass('hide');
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'retreats':{
            init: function () {

                $('.hover').mouseenter(function () {
                    $(this).find('.read-more').fadeTo('slow', 1);
                    $(this).find('.meta').toggleClass('hide');
                }).mouseleave(function () {
                    $(this).find('.read-more').fadeTo('slow', 0);
                    $(this).find('.meta').toggleClass('hide');
                });

                var $el = $('.carousel');

                $(window).scroll(function(event) {
                    if ($el.visible(true)) {
                        $el.carousel(0);
                    }
                });
            }
        },
        'love': {
            init: function () {
                var wookmark = {};

                $("#view-more").click(function (event) {
                    event.preventDefault();
                    displayLoves();
                });

                $("#view-less").click(function () {
                    event.preventDefault();

                    $('html, body').animate({scrollTop : 0},800);
                    setTimeout(function(){
                        $('.grid').each(function(i, love){
                            $(love).addClass('hide');
                        });
                        displayLoves();
                    }, 1000);

                });

                function displayLoves(){
                    var hiddenLoves = $('.grid.hide');
                    hiddenLoves.each(function(i, love){
                       if(i < 9)
                           $(love).toggleClass('hide');
                    });

                    hiddenLoves = $('.grid.hide');


                    refreshGrid();

                    if(hiddenLoves.length > 0)
                    {
                        $('#view-less').addClass('hide');
                        $('#view-more').removeClass('hide');
                    }
                    else {
                        $('#view-less').removeClass('hide');
                        $('#view-more').addClass('hide');
                    }
                };


                displayLoves();


            }
        },
        'search': {
            init: function () {
                $(window).load(function () {
                    $('article .img .read-more').each(function () {
                        var imgHeight = $(this).next().height();
                        $(this).height(imgHeight);
                    });
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
